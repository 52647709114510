<template>
  <a-modal
    :maskClosable="false"
    dialogClass="translate-modal"
    :width="400"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div @mouseover="topCenterMouseover" slot="title" class="modal-title">注音</div>
    <a-spin :spinning="loading">
      <div @mouseover="topCenterMouseover">
        <a-row style="font-weight:700;padding-bottom:12px">
          <a-col :span="10">
            <div>文字</div>
          </a-col>
          <a-col :span="14">
            <div>注音</div>
          </a-col>
          <!--           <a-col :span="5">
            <div>字高</div>
          </a-col>
          <a-col :span="5">
            <div>字间距</div>
          </a-col> -->
        </a-row>
        <div class="text-list">
          <template v-if="saveType == 'single'">
            <a-row
              v-for="text in textArr.filter(
                text =>
                  text.characterText.indexOf('<br>') == -1 &&
                  text.characterText.indexOf('\r') == -1 &&
                  text.characterText.indexOf('\n') == -1 &&
                  text.characterText.indexOf(' ') == -1 &&
                  getCharacterType(text.characterText) == '汉字'
              )"
              :key="text.characterSentenceId"
            >
              <a-col :span="10">
                <div>{{ text.characterText }}</div>
              </a-col>
              <a-col :span="14">
                <div>
                  <a-input
                    @input="e => textInput(text, 0, e)"
                    placeholder="请输入注音"
                    v-model="text.phonetic.phoneticText"
                  />
                </div>
              </a-col>
              <!--               <a-col :span="5">
                <div>
                  <a-select
                    placeholder="请选择字高"
                    :default-value="text.phonetic.scaleY"
                    v-model="text.phonetic.scaleY"
                    style="width: 100%"
                  >
                    <a-select-option :value="1">
                      100%
                    </a-select-option>
                    <a-select-option :value="0.7">
                      70%
                    </a-select-option>
                    <a-select-option :value="0.6">
                      60%
                    </a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :span="5">
                <div>
                  <a-select
                    placeholder="请选择字间距"
                    :default-value="text.phonetic.letterSpacing"
                    v-model="text.phonetic.letterSpacing"
                    style="width: 100%"
                  >
                    <a-select-option :value="text.phonetic.letterSpacingOption">
                      -100
                    </a-select-option>
                    <a-select-option :value="0">
                      0
                    </a-select-option>
                  </a-select>
                </div>
              </a-col> -->
            </a-row>
          </template>
          <template v-else>
            <a-row>
              <a-col :span="10">
                <div>
                  {{ textArr.map(text => text.characterText).join('') }}
                </div>
              </a-col>
              <a-col :span="14">
                <div>
                  <a-input
                    @input="e => textInput(textArr[0], 1, e)"
                    placeholder="请输入注音"
                    v-model="multiplePhoneticText"
                  />
                </div>
              </a-col>
              <!--               <a-col :span="5">
                <div>
                  <a-select
                    placeholder="请选择字高"
                    :default-value="multipleScaleY"
                    v-model="multipleScaleY"
                    style="width: 100%"
                  >
                    <a-select-option :value="1">
                      100%
                    </a-select-option>
                    <a-select-option :value="0.7">
                      70%
                    </a-select-option>
                    <a-select-option :value="0.6">
                      60%
                    </a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :span="5">
                <div>
                  <a-select
                    placeholder="请选择字间距"
                    :default-value="multipleLetterSpacing"
                    v-model="multipleLetterSpacing"
                    style="width: 100%"
                  >
                    <a-select-option :value="multipleLetterSpacingOption">
                      -100
                    </a-select-option>
                    <a-select-option :value="0">
                      0
                    </a-select-option>
                  </a-select>
                </div>
              </a-col> -->
            </a-row>
          </template>
        </div>
        <!--         <a-row style="margin-top:20px;" v-if="textShow">
                    <a-row>
            <a-col :span="4">
              <div class="text-show-option">字号</div>
            </a-col>
            <a-col :span="10">
              <div class="text-show-option">
                <a-select
                  placeholder="请选择字号"
                  @change="fontChange"
                  :default-value="fontSize"
                  v-model="fontSize"
                  style="width: 100%"
                >
                  <a-select-option v-for="size in fontSizes" :value="size.value" :key="size.value">
                    {{ size.label }}
                  </a-select-option>
                </a-select>
              </div>
            </a-col>
            <a-col :span="5" style="padding-right:12px;">
              <div class="text-show-option" style="text-align:right;padding-right:4px;">行距</div>
            </a-col>
            <a-col :span="5">
              <div>
                <div class="text-show-option">
                  <a-select
                    placeholder="请选择行距"
                    :default-value="lineSpacing"
                    @change="lineSpacingChange"
                    v-model="lineSpacing"
                    style="width: 100%"
                  >
                    <a-select-option v-for="size in lineSpacings" :key="size.value">
                      {{ size.label }}
                    </a-select-option>
                  </a-select>
                </div>
              </div></a-col
            >
          </a-row>
          <a-row>
            <a-col :span="4">
              <div style="font-weight:700;padding-bottom:10px">预览</div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <div
                class="text-show"
                :style="{
                  paddingBottom:
                    textLayout == 2
                      ? '0px'
                      : textArr[textArr.length - 1] &&
                        textArr[textArr.length - 1].fontSize *
                          (textArr.filter(text => text.characterText == '<br>').length + 1) +
                          20 +
                          'px',
                  paddingTop: '30px'
                }"
              >
                <div
                  :style="{
                    writingMode: textLayout == 2 ? 'vertical-rl' : 'horizontal-tb',
                    textOrientation: textLayout == 2 ? 'upright' : 'initial',
                    textAlign: textAlign,
                    lineHeight:
                      textArr[0] &&
                      (
                        textArr[0].lineSpacing *
                        (this.showImgLeftListPostions[0].width / this.pageListData[0].width)
                      ).toFixed(2)
                  }"
                >
                  <template v-for="(text, textIdx) in textArr">
                    <ruby v-if="getText(text.characterText)" :key="text.characterSentenceId">
                      <span
                        class="stroke-span"
                        :data-text="text.phonetic && text.phonetic.phoneticText"
                        :style="getTextStyle(text)"
                        ><span
                          class="stroke-span-child"
                          :data-text="text.characterText != '' ? text.characterText : ''"
                          :style="
                            'display:inline-block;white-space: pre-wrap;' +
                              `transform:scale(${text.textWidth},${text.textHeight});` +
                              `${
                                textLayout == 2 && text.characterText == ' ' ? `height:${text.fontSize / 3}px;` : ''
                              }` +
                              `${
                                text.characterText == ' ' &&
                                textArr[textIdx - 1] &&
                                textArr[textIdx - 1].characterText == '<br>'
                                  ? 'width:0;height:0;'
                                  : ''
                              }` +
                              `-webkit-text-stroke: ${text.strokeWidth * 2}px ${text.strokeColor};user-select:none;`
                          "
                          >{{ text.characterText != '' ? text.characterText : '' }}</span
                        ></span
                      >
                    </ruby>
                    <br v-else :key="text.characterSentenceId" />
                  </template>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-row> -->
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { getAction, postAction } from '../../../api/manage'
export default {
  props: {
    textLayout: {
      type: Number,
      default: 2
    },
    phoneticStyle: {
      type: Object,
      default: () => ({
        fontColor: '#000000',
        fontSize: 9,
        lineSpacing: 1.15,
        scaleX: 1,
        scaleY: 1,
        textAlign: 'left'
      })
    },
    textBatchStyle: {
      type: Object,
      default: () => ({})
    },
    pageListData: {
      type: Array,
      default: () => []
    },
    showImgLeftListPostions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      textShow: true,
      textAlign: 'left',
      saveType: 'single',
      multiplePhoneticText: '',
      multipleScaleY: 1,
      multipleLetterSpacing: 0,
      multipleLetterSpacingOption: 1,
      textArr: [],
      oldTextArr: [],
      letterSpacing: 1,
      fontSizes: [
        {
          label: 1,
          value: 5
        },
        {
          label: 2,
          value: 9
        },
        {
          label: 3,
          value: 13
        },
        {
          label: 4,
          value: 17
        },
        {
          label: 5,
          value: 21
        },
        {
          label: 6,
          value: 25
        },
        {
          label: 7,
          value: 29
        },
        {
          label: 7.5,
          value: 31
        },
        {
          label: 8,
          value: 33
        },
        {
          label: 8.5,
          value: 35
        },
        {
          label: 9,
          value: 37
        },
        {
          label: 9.5,
          value: 39
        },
        {
          label: 10,
          value: 41
        },
        {
          label: 11,
          value: 45
        },
        {
          label: 12,
          value: 49
        },
        {
          label: 14,
          value: 57
        },
        {
          label: 18,
          value: 73
        },
        {
          label: 24,
          value: 97
        },
        {
          label: 30,
          value: 121
        },
        {
          label: 36,
          value: 145
        },
        {
          label: 48,
          value: 197
        },
        {
          label: 60,
          value: 241
        },
        {
          label: 72,
          value: 289
        }
      ],
      lineSpacings: [
        {
          label: '0',
          value: 0
        },
        {
          label: '50%',
          value: 0.5
        },
        {
          label: '60%',
          value: 0.6
        },
        {
          label: '70%',
          value: 0.7
        },
        {
          label: '80%',
          value: 0.8
        },
        {
          label: '90%',
          value: 0.9
        },
        {
          label: '100%',
          value: 1
        },
        {
          label: '110%',
          value: 1.1
        },
        {
          label: '115%',
          value: 1.15
        },
        {
          label: '120%',
          value: 1.2
        },
        {
          label: '130%',
          value: 1.3
        },
        {
          label: '140%',
          value: 1.4
        },
        {
          label: '150%',
          value: 1.5
        }
      ],
      fontSize: 17,
      lineSpacing: 1.15,
      selectData: []
    }
  },
  methods: {
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    textInput(text, type, e) {
      if (text.phonetic && text.phonetic.targetCharacterIds.length == 1) {
        if (this.textLayout == 2) {
          if (text.phonetic.phoneticText.length < 3) {
            text.phonetic.scaleY = 1
            text.phonetic.letterSpacing = 0
          } else if (text.phonetic.phoneticText.length == 3) {
            text.phonetic.scaleY = 0.7
            text.phonetic.letterSpacing = text.phonetic.letterSpacingOption
          } else {
            text.phonetic.scaleY = 0.6
            text.phonetic.letterSpacing = 0
          }
        } else {
          if (text.phonetic.phoneticText.length < 3) {
            text.phonetic.scaleX = 1
            text.phonetic.letterSpacing = 0
          } else if (text.phonetic.phoneticText.length == 3) {
            text.phonetic.scaleX = 0.7
            text.phonetic.letterSpacing = text.phonetic.letterSpacingOption
          } else {
            text.phonetic.scaleX = 0.6
            text.phonetic.letterSpacing = 0
          }
        }
      }
      if (type) {
        text.phonetic.phoneticText = this.multiplePhoneticText
      } else {
        text.phonetic.phoneticText = e.target.value
      }
      this.$forceUpdate()
    },
    getText(characterText) {
      let text = false
      text = characterText.indexOf('<br>') > -1 ? false : true
      return text
    },
    getTextStyle(text) {
      let pNums = []
      let brIndexs = [0]
      let pIndexs = []
      let textIdx = this.textArr.findIndex(t => t.characterSentenceId == text.characterSentenceId)
      this.textArr.forEach((t, index) => {
        if (t.characterText == '<br>') brIndexs.push(index)
        if (
          this.textArr[index + 1] &&
          this.textArr[index + 1].phonetic &&
          this.textArr[index + 1].phonetic.phoneticText
        )
          pIndexs.push(index)
      })
      if (this.textArr[0] && this.textArr[0].phonetic && this.textArr[0].phonetic.phoneticText) pIndexs.unshift(0)
      if (
        this.textArr[this.textArr.length - 1] &&
        this.textArr[this.textArr.length - 1].phonetic &&
        this.textArr[this.textArr.length - 1].phonetic.phoneticText
      ) {
        pIndexs.pop(this.textArr.length - 1)
      }
      pIndexs.forEach(pIndex => {
        brIndexs.forEach((brIndex, idx) => {
          if (
            brIndexs[idx + 1] &&
            pIndex >= brIndex &&
            pIndex < brIndexs[idx + 1] &&
            pNums.every(p => p.starIdx != brIndex && p.endIdx != brIndexs[idx + 1])
          ) {
            pNums.push({ starIdx: brIndex, endIdx: brIndexs[idx + 1] })
          }
          if (
            !brIndexs[idx + 1] &&
            pIndex >= brIndex &&
            pNums.every(p => p.starIdx != brIndex && p.endIdx != textIdx)
          ) {
            pNums.push({ starIdx: brIndex, endIdx: textIdx })
          }
        })
      })
      let pNum = 0
      pNums.forEach(p => {
        if (textIdx >= p.starIdx) pNum++
      })
      let lineSpacing = text.lineSpacing
      let textMove = (pNum * text.fontSize * lineSpacing).toFixed(0) + 'px;'
      if (text.phonetic) {
        textMove = (pNum * text.phonetic.fontSize * lineSpacing).toFixed(0) + 'px;'
      } else {
        if (
          this.textArr.findIndex(
            t => t.phonetic && t.phonetic.targetCharacterIds.some(targetId => targetId == text.characterSentenceId)
          ) > -1
        ) {
          let fs = this.textArr.find(
            t => t.phonetic && t.phonetic.targetCharacterIds.some(targetId => targetId == text.characterSentenceId)
          ).phonetic.fontSize
          textMove = (pNum * fs * lineSpacing).toFixed(0) + 'px;'
        }
      }
      if (this.textLayout == 2) {
        textMove = 'left:-' + textMove
      } else {
        textMove = 'top:' + textMove
      }
      let style =
        `font-size:${text.fontSize}px;` +
        `color:${text.fontColor};` +
        `font-family:${text.fontFamily ? 'fontCssView' + text.fontFamily : ''};` +
        `font-weight:${text.isBold ? 'bold' : ''};` +
        `font-style:${text.isIncline ? 'italic' : ''};` +
        `transform:scale(${text.textWidth},${text.textHeight});position:relative;` +
        textMove
      if (text.phonetic) {
        let top = 0
        let left = 0
        if (this.textLayout == 2) {
          let changeLeft = ((text.fontSize - text.phonetic.fontSize) / 3).toFixed(0) - 0
          left =
            (
              (pNum ? 1 : 0) * text.fontSize * text.lineSpacing -
              (text.fontSize * text.lineSpacing - text.phonetic.fontSize) / 2
            ).toFixed(0) -
            changeLeft +
            'px;'
          top = '0px;'
        } else {
          let changeTop = ((text.fontSize - text.phonetic.fontSize) / 3).toFixed(0) - 0
          top =
            '-' +
            ((pNum ? 1 : 0) * text.fontSize * text.lineSpacing - (text.fontSize - text.phonetic.fontSize) / 2).toFixed(
              0
            ) -
            0 +
            changeTop +
            'px;'
          left = '0px;'
        }
        let lineHeightW = 'calc(100% - 2px);'
        let lineHeightH = 'calc(100% - 2px);'
        if (this.textLayout == 2) {
          lineHeightH = `${text.phonetic.phoneticText.length * text.phonetic.fontSize}px;`
          top =
            (
              (text.fontSize * text.phonetic.targetCharacterIds.length -
                text.phonetic.phoneticText.length * text.phonetic.fontSize) /
              2
            ).toFixed(0) + 'px;'
        } else {
          lineHeightW = `${text.phonetic.phoneticText.length * text.phonetic.fontSize}px;`
          /*           if (text.phonetic.lineSpacing && text.phonetic.lineSpacing * 100 - 70 > 0) {
            top = `-${(text.phonetic.lineSpacing * 100 - 70).toFixed(0)}%;`
          } else {
            top = `${(70 - text.phonetic.lineSpacing * 100).toFixed(0)}%;`
          } */
          left =
            (
              (text.fontSize * text.phonetic.targetCharacterIds.length -
                text.phonetic.phoneticText.length * text.phonetic.fontSize) /
              2
            ).toFixed(0) + 'px;'
        }
        let pStyle =
          `--pFontSize: ${text.phonetic.fontSize}px;` +
          `--pLineHeightW:${lineHeightW}` +
          `--pLineHeightH:${lineHeightH}` +
          `--transform:scale(${text.phonetic.scaleX || 1},${text.phonetic.scaleY || 1});` +
          `--letterSpacing:-${text.phonetic.letterSpacing}px;` +
          `--bold:${text.isBold ? 'bold;' : ''};` +
          `--isIncline:${text.isIncline ? 'italic;' : ''};` +
          `--stroke:${(text.strokeWidth * 2).toFixed(0) - 0}px ${text.strokeColor};` +
          `--pBack:${text.strokeColor};` +
          `--pTop:${top}` +
          `--pLeft:${left}`
        style = style + pStyle
      }
      return style
    },
    lineSpacingChange(value) {
      this.textArr.forEach(text => {
        text.phonetic.lineSpacing = value
      })
    },
    fontChange(value) {
      this.textArr.forEach(text => {
        text.phonetic.fontSize = value
        if (text.phonetic.letterSpacing) {
          text.phonetic.letterSpacing = (value / 10).toFixed(0) - 0
        }
        text.phonetic.letterSpacingOption = (value / 10).toFixed(0) - 0
      })
    },
    async open(textArr, type, selectData) {
      this.visible = true
      this.saveType = type
      this.oldTextArr = textArr
      this.textArr = JSON.parse(JSON.stringify(textArr))
      this.textArr.forEach(text => {
        if (!text.phonetic) {
          text.phonetic = {
            fontSize: (text.fontSize - 1) / 2 + 1,
            letterSpacing: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0,
            scaleY: this.phoneticStyle.scaleY,
            scaleX: this.phoneticStyle.scaleX,
            lineSpacing: this.phoneticStyle.lineSpacing || 1.15,
            textAlign: this.phoneticStyle.textAlign,
            fontColor: this.phoneticStyle.fontColor,
            phoneticText: '',
            targetCharacterIds: [text.characterSentenceId],
            letterSpacingOption: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0
          }
          this.multipleScaleY = this.phoneticStyle.scaleY
          this.multipleLetterSpacing = this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0
          this.multipleLetterSpacingOption = this.phoneticStyle.scaleX
            ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0
            : 0
        } else {
          text.phonetic.phoneticText = ''
          text.phonetic.letterSpacingOption = text.phonetic.fontSize ? (text.phonetic.fontSize / 10).toFixed(0) - 0 : 1
          this.multipleLetterSpacingOption = text.phonetic.fontSize ? (text.phonetic.fontSize / 10).toFixed(0) - 0 : 1
          this.multiplePhoneticText = text.phonetic.phoneticText
          this.multipleScaleY = text.phonetic.scaleY
          this.multipleLetterSpacing = text.phonetic.letterSpacing
          this.lineSpacing = text.phonetic.lineSpacing || 1.15
          text.phonetic.targetCharacterIds = [text.characterSentenceId]
        }
      })
      if (selectData) {
        selectData[0].characterSentences.forEach(text => {
          if (!text.phonetic) {
            text.phonetic = {
              fontSize: (text.fontSize - 1) / 2 + 1,
              letterSpacing: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0,
              scaleY: this.phoneticStyle.scaleY,
              scaleX: this.phoneticStyle.scaleX,
              lineSpacing: this.phoneticStyle.lineSpacing || 1.15,
              textAlign: this.phoneticStyle.textAlign,
              fontColor: this.phoneticStyle.fontColor,
              phoneticText: '',
              targetCharacterIds: [text.characterSentenceId],
              letterSpacingOption: this.phoneticStyle.scaleX ? (this.phoneticStyle.fontSize / 10).toFixed(0) - 0 : 0
            }
          }
        })
        this.selectData = selectData
      }
      if (this.textArr[0].phonetic) {
        this.fontSize = this.textArr[0].phonetic.fontSize
      } else {
        this.fontSize = (this.textArr[0].fontSize - 1) / 2 + 1
      }
      this.textAlign = this.textBatchStyle.textAlign
      /* this.fontSize = this.phoneticStyle.fontSize */
      if (type != 'single') {
        this.textArr.forEach((text, index) => {
          if (index) {
            text.phonetic = null
          } else {
            text.phonetic.targetCharacterIds = this.textArr.map(text => text.characterSentenceId)
          }
        })
      }
    },
    getCharacterType(character) {
      const hiraganaRegex = /hiragana/ // 平假名正则表达式
      const katakanaRegex = /katakana/ // 片假名正则表达式
      const hanziRegex = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u2e80-\u2eff]|[\u3190-\u319f]|[\ufe30-\ufe4f]|[\uf900-\ufd3d]|[\u2f00-\u2fdf]/g // 汉字正则表达式

      if (hiraganaRegex.test(character)) {
        return '平假名'
      } else if (katakanaRegex.test(character)) {
        return '片假名'
      } else if (hanziRegex.test(character)) {
        return '汉字'
      } else {
        return '其他字符'
      }
    },
    handleOk() {
      if (this.saveType != 'single') {
        this.textArr[0].phonetic.phoneticText = this.multiplePhoneticText
      } else {
        this.textArr.forEach(text => {
          if (text.phonetic) {
            text.phonetic.targetCharacterIds = [text.characterSentenceId]
          }
        })
      }
      let checkTargetId = false
      if (this.selectData[0]) {
        this.textArr.forEach(text => {
          this.selectData[0].characterSentences.forEach(t => {
            if (t.phonetic && t.phonetic.targetCharacterIds.some(targetId => targetId == text.characterSentenceId)) {
              if (t.phonetic.targetCharacterIds.findIndex(id => id == text.characterSentenceId) > 0) {
                checkTargetId = true
              }
            }
          })
        })
      }
      /*       if (checkTargetId) {
        this.$message.error('检测到当前被注音文本已有注音，请注意检查')
        return
      } */
 /*      this.textArr.forEach((t, index) => {
        this.oldTextArr.forEach((ot, oIndex) => {
          if (index == oIndex) {
            t.characterSentenceId = ot.characterSentenceId
          }
        })
      })
      if (this.saveType != 'single') {
        this.textArr.forEach((text, index) => {
          if (index) {
            text.phonetic = null
          } else {
            text.phonetic.targetCharacterIds = this.oldTextArr.map(text => text.characterSentenceId)
          }
        })
      } else {
        this.textArr.forEach(text => {
          if (text.phonetic) {
            text.phonetic.targetCharacterIds = [text.characterSentenceId]
          }
        })
      } */
      this.multiplePhoneticText = ''
      this.visible = false
      this.selectData = []
      this.$nextTick(()=>{
        this.$emit('savePhonetic', this.textArr)
      })
    },
    handleCancel() {
      this.multiplePhoneticText = ''
      this.oldTextArr = []
      this.textArr = []
      this.selectData = []
      this.visible = false
      this.$emit('changeIsOpenPhoneticModal')
    }
  }
}
</script>

<style lang="less" scoped>
.stroke-span::after {
  content: attr(data-text);
  position: absolute;
  white-space: nowrap;
  z-index: 0;
  left: var(--pLeft);
  top: var(--pTop);
  -webkit-text-stroke: 0;
  text-align: center;
  font-size: var(--pFontSize) !important;
  line-height: normal;
  transform: var(--transform);
  font-weight: var(--bold);
  font-style: var(--isIncline);
  letter-spacing: var(--letterSpacing);
  height: var(--pLineHeightH);
  width: var(--pLineHeightW);
  user-select: none;
  display: inline-block;
  -webkit-text-stroke: var(--stroke);
}
.stroke-span-child {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
.stroke-span-child::after {
  content: attr(data-text);
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  -webkit-text-stroke: 0;
}
.stroke-span::before {
  content: attr(data-text);
  position: absolute;
  z-index: 1;
  left: var(--pLeft);
  top: var(--pTop);
  -webkit-text-stroke: 0;
  text-align: center;
  font-size: var(--pFontSize) !important;
  line-height: normal;
  transform: var(--transform);
  font-weight: var(--bold);
  font-style: var(--isIncline);
  letter-spacing: var(--letterSpacing);
  height: var(--pLineHeightH);
  width: var(--pLineHeightW);
  user-select: none;
  display: inline-block;
}
.stroke-span {
  z-index: 1000;
}
/deep/ .ant-select-dropdown {
  position: relative;
}
.text-show {
  background-color: #f0f2f5;
  display: flex;
  justify-content: center;
  padding: 12px;
  /*   overflow-y: auto;
  max-height: 200px; */
}
.text-list {
  line-height: 32px;
  max-height: 300px;
  overflow-y: auto;
  .ant-row {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.ant-col {
  padding: 5px 0;
  padding-left: 3px;
  padding-right: 12px;
}
::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
.text-show-option {
  line-height: 32px;
  padding-bottom: 20px;
}
::-webkit-scrollbar {
  border-radius: 0;
  position: relative;
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #fff;
}
::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
.modal-title {
  height: 36px;
  background-color: #ebebeb;
  padding: 2px 12px 2px 12px;
  line-height: 32px;
  font-weight: 400;
}
/deep/ .translate-modal .ant-modal-header {
  padding: 0 !important;
}
/deep/ .translate-modal .ant-modal-body {
  padding: 12px 24px;
}
/deep/ .translate-modal .ant-modal-close {
  height: 36px;
}
/deep/ .translate-modal .ant-modal-close-x {
  height: 36px;
  line-height: 36px;
}
</style>
