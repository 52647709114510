var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        maskClosable: false,
        dialogClass: "translate-modal",
        width: 400,
        visible: _vm.visible,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-title",
          attrs: { slot: "title" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "title",
        },
        [_vm._v("注音")]
      ),
      _c("a-spin", { attrs: { spinning: _vm.loading } }, [
        _c(
          "div",
          { on: { mouseover: _vm.topCenterMouseover } },
          [
            _c(
              "a-row",
              {
                staticStyle: { "font-weight": "700", "padding-bottom": "12px" },
              },
              [
                _c("a-col", { attrs: { span: 10 } }, [
                  _c("div", [_vm._v("文字")]),
                ]),
                _c("a-col", { attrs: { span: 14 } }, [
                  _c("div", [_vm._v("注音")]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-list" },
              [
                _vm.saveType == "single"
                  ? _vm._l(
                      _vm.textArr.filter(
                        (text) =>
                          text.characterText.indexOf("<br>") == -1 &&
                          text.characterText.indexOf("\r") == -1 &&
                          text.characterText.indexOf("\n") == -1 &&
                          text.characterText.indexOf(" ") == -1 &&
                          _vm.getCharacterType(text.characterText) == "汉字"
                      ),
                      function (text) {
                        return _c(
                          "a-row",
                          { key: text.characterSentenceId },
                          [
                            _c("a-col", { attrs: { span: 10 } }, [
                              _c("div", [_vm._v(_vm._s(text.characterText))]),
                            ]),
                            _c("a-col", { attrs: { span: 14 } }, [
                              _c(
                                "div",
                                [
                                  _c("a-input", {
                                    attrs: { placeholder: "请输入注音" },
                                    on: {
                                      input: (e) => _vm.textInput(text, 0, e),
                                    },
                                    model: {
                                      value: text.phonetic.phoneticText,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          text.phonetic,
                                          "phoneticText",
                                          $$v
                                        )
                                      },
                                      expression: "text.phonetic.phoneticText",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      }
                    )
                  : [
                      _c(
                        "a-row",
                        [
                          _c("a-col", { attrs: { span: 10 } }, [
                            _c("div", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.textArr
                                      .map((text) => text.characterText)
                                      .join("")
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                          _c("a-col", { attrs: { span: 14 } }, [
                            _c(
                              "div",
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入注音" },
                                  on: {
                                    input: (e) =>
                                      _vm.textInput(_vm.textArr[0], 1, e),
                                  },
                                  model: {
                                    value: _vm.multiplePhoneticText,
                                    callback: function ($$v) {
                                      _vm.multiplePhoneticText = $$v
                                    },
                                    expression: "multiplePhoneticText",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }